import { useSortable } from '@dnd-kit/sortable'
import cn from 'classnames'
import * as React from 'react'
import { usePositionsTable } from '../../../hooks/use-positions-table'
import { DisciplineSection, DisciplineSectionProps } from './DisciplineSection'
import styles from './DisciplineSection.module.scss'

export const DraggableDisciplineSection: React.VFC<DisciplineSectionProps> = (
  props
) => {
  const permissions = usePositionsTable((s) => s.permissions)
  const { setNodeRef, attributes, listeners, isDragging } = useSortable({
    id: `position-${props.id}`,
    disabled: !permissions.allowEditDiscipline,
    data: {
      id: props.discipline?.id,
    },
  })

  return (
    <div
      className={cn(styles.wrapper, isDragging && styles.dragging)}
      key={props.id}
    >
      <DisciplineSection
        {...props}
        dragHandleProps={{
          ...listeners,
          ...attributes,
        }}
        ref={setNodeRef}
      />
    </div>
  )
}
