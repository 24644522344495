import * as React from 'react'
import { SortableContext } from '@dnd-kit/sortable'
import shallow from 'zustand/shallow'
import cn from 'classnames'
import {
  defaultDropAnimation,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
} from '@dnd-kit/core'
import { DisciplineSection } from './DisciplineSection'
import type { TDiscipline } from '../../../types/entities'
import styles from './PositionsTable.module.scss'
import {
  usePositionsTable,
  UsePositionsTableProps,
} from '../../../hooks/use-positions-table'
import { DraggableDisciplineSection } from './DraggableDisciplineSection'
import { useDraggableDisciplines } from './use-draggable-disciplines'
import { AddResourceButton } from '../../molecules/AddResourceButton'
import { useDndSensors } from '../../../hooks/use-dnd-sensors'

const screenReaderInstructions = {
  draggable:
    'Press enter to either view or create a position. You can reorder disciplines by dragging them; press space bar to start a drag. When dragging you can use the arrow keys to move the item around and escape to cancel. Some screen readers may require you to be in focus mode or to use your pass through key.',
}

export type PositionsTableProps = {
  disciplines?: TDiscipline[]
} & Partial<Pick<UsePositionsTableProps, 'permissions' | 'eventHandlers'>> &
  React.HTMLAttributes<HTMLDivElement>

export const PositionsTable: React.VFC<PositionsTableProps> = (props) => {
  const {
    className,
    permissions,
    eventHandlers: initialEventHandlers,
    disciplines: initialDisciplines,
    ...restProps
  } = props

  const [
    setPermissions,
    setEventHandlers,
    eventHandlers,
    disciplines,
    setDisciplines,
  ] = usePositionsTable(
    (s) => [
      s.setPermissions,
      s.setEventHandlers,
      s.eventHandlers,
      s.disciplines,
      s.setDisciplines,
    ],
    shallow
  )

  React.useEffect(() => {
    if (permissions) setPermissions(permissions)
    if (initialEventHandlers) setEventHandlers(initialEventHandlers)
    if (initialDisciplines) setDisciplines(initialDisciplines)
  }, [initialDisciplines, initialEventHandlers, permissions])

  // send an initial copy of the disciplines to the hook so we can revert back
  // if anything fails
  const { ids, eventHandler, draggedDiscipline, setDraggedDiscipline, reset } =
    useDraggableDisciplines(initialDisciplines)

  const sensors = useDndSensors()

  React.useEffect(() => {
    const handler = (e: DragEvent) => e.preventDefault()
    window.addEventListener('dragstart', handler)

    return () => window.removeEventListener('dragstart', handler)
  }, [])

  return (
    <div className={cn(styles.positionsTable, className)} {...restProps}>
      <DndContext
        onDragStart={(e) => eventHandler.handleDragStart(e)}
        onDragOver={(e) => eventHandler.handleDragOver(e)}
        onDragEnd={async () => {
          if (!disciplines) return
          await eventHandler.handleDragEnd()
        }}
        sensors={sensors}
        onDragCancel={() => {
          setDraggedDiscipline(null)
          reset()
        }}
        accessibility={{
          screenReaderInstructions: screenReaderInstructions,
        }}
      >
        <SortableContext items={ids.map((id) => `position-${id}`)}>
          {ids.map((id, index) => {
            return (
              <DraggableDisciplineSection
                firstOfType={index === 0}
                id={id.toString()}
                discipline={disciplines?.find((d) => d.id.toString() === id)}
                key={id}
              />
            )
          })}
        </SortableContext>
        <DragOverlay
          dropAnimation={{
            ...defaultDropAnimation,
            sideEffects: defaultDropAnimationSideEffects({
              styles: {
                active: {
                  opacity: '0.5',
                },
              },
            }),
          }}
        >
          {draggedDiscipline ? (
            <DisciplineSection
              id={draggedDiscipline.id.toString()}
              discipline={draggedDiscipline}
              className={styles.overlay}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
      {permissions?.allowEditFramework && (
        <AddResourceButton
          className={styles.addDisciplineButton}
          onClick={() => eventHandlers.onAddDiscipline?.()}
        >
          Add a track
        </AddResourceButton>
      )}
    </div>
  )
}
