import { arrayMove } from '@dnd-kit/sortable'
import * as React from 'react'
import { usePositionsTable } from '../../../hooks/use-positions-table'
import type { TDiscipline } from '../../../types/entities'
import { sortBy } from '../../../utils/array-helpers'
import { PositionsTableEventHandler } from './PositionsTableEventHandler'

export const useDraggableDisciplines = (initialDisciplines?: TDiscipline[]) => {
  const [eventHandlers, storeDisciplines, setStoreDisciplines] =
    usePositionsTable((s) => [s.eventHandlers, s.disciplines, s.setDisciplines])

  const [originalDisciplines, setOriginalDisciplines] = React.useState<
    TDiscipline[]
  >(sortBy(initialDisciplines || [], 'sortOrder'))

  const [draggedDiscipline, setDraggedDiscipline] =
    React.useState<TDiscipline | null>(null)

  const ids = storeDisciplines.map((l) => l.id.toString())

  const reorderDiscipline = (oldIndex: number, newIndex: number) => {
    if (newIndex === undefined) return

    const disciplines = arrayMove(storeDisciplines, oldIndex, newIndex)

    const disciplinesWithUpdatedSortOrder = disciplines.map((d, index) => ({
      ...d,
      sortOrder: index + 1,
    }))

    setStoreDisciplines(disciplinesWithUpdatedSortOrder)
  }

  const reset = () => setStoreDisciplines(originalDisciplines)

  const updateDisciplines = React.useCallback(async () => {
    if (
      originalDisciplines.every(
        ({ id }, index) => id === storeDisciplines[index].id
      )
    ) {
      // if the original sort order is the same as the updated one in the store,
      // don't call the eventHandler since nothing has changed
      return
    }

    // call the API
    const success = await eventHandlers.onReorderDisciplines?.(storeDisciplines)

    if (success) {
      setOriginalDisciplines(storeDisciplines)
    } else {
      reset()
    }
  }, [storeDisciplines])

  const eventHandler = React.useMemo(() => {
    return new PositionsTableEventHandler(
      storeDisciplines,
      reorderDiscipline,
      updateDisciplines,
      setDraggedDiscipline
    )
  }, [storeDisciplines, eventHandlers])

  return {
    ids,
    draggedDiscipline,
    setDraggedDiscipline,
    eventHandler,
    reset,
  }
}
