import { DragMoveEvent, DragOverEvent, Over } from '@dnd-kit/core'
import { isEmpty } from '../object-manipulation'
import { omit } from '../omit'

interface DragData {
  data: {
    current?: { id?: number; type?: string; [key: string]: unknown }
  }
}

interface SortEvent extends DragOverEvent {
  over: DragOverEvent['over'] &
    DragData & {
      data: {
        current?: {
          sortable: {
            containerId: string
            index: number
          }
        }
      }
    }
}

// The attributes we care about from a DnDKit drag event
export type DragEvent = {
  active: Pick<DragMoveEvent['active'], 'id'> & DragData
  over?: (Pick<Over, 'data' | 'id'> & DragData) | null
}

export interface ParsedDragEvent {
  /**
   * ID of the dragged item
   */
  itemId?: number
  /**
   * ID of the item the dragged item is being dragged over
   */
  targetItemId?: number
  /**
   * If a droppable or sortable event, the ID of the
   * container the item has been droppped in.
   */
  targetContainerId?: number
  /**
   * If a sortable event, the index the item was dropped into
   */
  targetIndex?: number
  /**
   * The type of the dragged item, useful in the case of multiple
   * nested draggables
   *
   * @example for a skill card
   * { type: "skill" }
   */
  type?: string
  /**
   * The type of the target item, useful in the case of multiple
   * nested draggables
   *
   * @example for a skill category
   * { type: "category" }
   */
  targetType?: string
  /**
   * Additional data associated with the drag event
   * @example for a skill
   * { skillType: "org" }
   */
  data?: Record<string, unknown>
}

/**
 * Is this event a Sortable event or a Droppable event?
 */
const isSortEvent = (e: DragEvent): e is SortEvent =>
  !!e.over?.data.current?.sortable

/**
 * Parse the relevant data out of a drag/drop event.
 * @param data - Dictionary of items keyed by their ID
 * @param e - Drag event from dndkit
 *
 * @returns ParsedDragEvent
 */
export const parseDragEvent = (
  e: DragEvent
): ParsedDragEvent | { [Attr in keyof ParsedDragEvent]?: undefined } => {
  const itemId = e.active.data.current?.id
  const type = e.active.data.current?.type
  let data = omit(e.active.data.current, ['id', 'type'])
  data = isEmpty(data) ? undefined : data

  if (!e.over) return { itemId, type, data }
  let targetContainerId: number | undefined,
    targetIndex: number | undefined,
    targetItemId: number | undefined

  if (isSortEvent(e)) {
    targetItemId = e.over.data.current?.id

    const targetContainerStrId = e.over.data.current?.sortable.containerId

    if (targetContainerStrId) {
      const parsedId = parseInt(targetContainerStrId, 10)
      targetContainerId = Number.isNaN(parsedId) ? undefined : parsedId
    }

    targetIndex = e.over.data.current?.sortable.index
  } else {
    targetContainerId = e.over.data.current?.id
    targetIndex = 0
  }

  return {
    data,
    itemId,
    targetItemId,
    targetContainerId,
    targetIndex,
    type,
    targetType: e.over?.data?.current?.type,
  }
}
