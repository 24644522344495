import type { DragOverEvent, DragStartEvent } from '@dnd-kit/core'
import { parseDragEvent } from '../../../utils/drag-n-drop/parse-event'
import type { TDiscipline } from '../../../types/entities'

export type DisciplinesDictionary = Record<string, TDiscipline>

export type PositionsTableEventHandlerDependencies = {
  parseDragEvent: typeof parseDragEvent
}

/**
 * A class containing event handlers for the drag events on the positions table.
 */
export class PositionsTableEventHandler {
  constructor(
    private disciplines: TDiscipline[],
    private reorderDiscipline: (oldIndex: number, newIndex: number) => void,
    private updateDisciplines: () => Promise<void>,
    private setDraggedDiscipline: (discipline: TDiscipline | null) => void,
    private dependencies: PositionsTableEventHandlerDependencies = {
      parseDragEvent,
    }
  ) {}

  /**
   * When a drag starts, we set the active dragged skill.
   */
  public handleDragStart(e: DragStartEvent) {
    const id = e.active.data.current?.id
    const discipline = id && this.disciplines.find((d) => d.id === id)
    if (discipline) this.setDraggedDiscipline(discipline)
  }

  /**
   * Whenever the dragged discipline's sort index changes
   * We update the local dictionary so the view changes, but we don't publish
   * a completed change until the drag completes.
   *
   * When the sort index changes, we pull all the disciplines and update
   * their sortIndex in the dictionary, which in turn updates the view.
   *
   * @see handleDragEnd
   * @see parseDragEvent
   */
  public handleDragOver(e: DragOverEvent) {
    const { itemId, targetIndex } = this.dependencies.parseDragEvent(e)
    const discipline = this.disciplines.find((d) => d.id === itemId)
    if (!discipline || targetIndex === undefined) return

    const currentIndex = this.disciplines.indexOf(discipline)

    this.reorderDiscipline(currentIndex, targetIndex)
  }

  /**
   * When the drag ends, we let the parent know what has changed.
   */
  public async handleDragEnd() {
    this.setDraggedDiscipline(null)

    this.updateDisciplines()
  }
}
